<template>
    <v-container fluid fill-height style="padding:0px" id="HomePage" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/main-bg.png`) + ')' }">
        <!-- <video oncontextmenu="return false;" id="myVideo" autoplay controls controlsList="nodownload">
            <source src="@/assets/game-intro.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video> -->
        <transition name="fade-transition">
            <div class="skip custom-font" v-if="showIntro && show" @click="skipVideo">Skip Intro</div>
        </transition>
        <!-- <transition name="fade-transition">
            <video autoplay id="myVideo" @ended="videoEnded" v-if="showIntro && !IntroWatched" :poster="require('@/assets/img/Untitled.jpg')" @loadeddata="checkLoading">
                <source src="@/assets/videos/Game_intro.mp4" type="video/mp4">
            </video>
        </transition> -->

        <transition name="fade-transition">
            <div class="intro-video-wrapper" v-if="showIntro && !IntroWatched && $store.state.user.role != 'teacher' && show">
                <div class="myVideoWrapper">
                    <video @click="videoPlay" :muted="$store.state.mute || introvideoMute" autoplay id="myVideo" @ended="videoEnded" :poster="require('@/assets/img/home-video-poster.png')" @loadeddata="checkLoading">
                        <source src="@/assets/videos/updated-game-intro.mp4" type="video/mp4">
                    </video>
                    <v-icon v-if="$store.state.mute || introvideoMute" class="mute-btn" @click="mute_or_unmute">
                        volume_off
                    </v-icon>
                    <v-icon v-if="!$store.state.mute || !introvideoMute" class="mute-btn" @click="mute_or_unmute">
                        volume_up
                    </v-icon>
                </div>
            </div>
        </transition>



        <transition name="fade-transition">
            <div class="full-screen-loader" v-if="videoLoading && !IntroWatched && $store.state.user.role != 'teacher' && !show">
                <v-progress-circular
                    indeterminate
                    color="#000000"
                    class="progressLoader">
                </v-progress-circular>
            </div>
        </transition>
        <!-- <transition name="fade-transition">
            <video autoplay id="myVideo" @ended="videoEnded2" v-if="showIntro2">
                <source src="@/assets/videos/Game_intro_2B.mp4" type="video/mp4">
            </video>
        </transition> -->
        <transition name="fade-transition">
            <v-container>
                <v-row v-if="(!showIntro && IntroWatched && show)">
                    <v-col cols="12" sm="1"></v-col>
                    <v-col cols="12" sm="7" class="homePageMenu">
                        <ul>
                            <li class="large-text"><router-link to="/rooms">ROOMS</router-link></li>
                            <li class="large-text"><router-link to="/scoreboard">SCOREBOARD</router-link></li>
                            <li class="large-text"><router-link to="/settings">SETTINGS</router-link></li>
                            <li class="large-text" v-if="User.role == 'teacher'"><router-link to="/curriculum">CURRICULUM</router-link></li>
                        </ul>
                    </v-col>
                    <v-col cols="12" sm="3" style="align-self: center;">
                        <v-img :src="require(`@/assets/2B-with-Beaker.png`)"></v-img>
                    </v-col>
                    <v-col cols="12" sm="1"></v-col>
                </v-row>
            </v-container>
        </transition>
    </v-container>
</template>

<script>
export default {
    name: 'Home',
    data(){
        return{
            showIntro: false,
            videoLoading: false,
            show: false,
            introvideoMute: true,
        }
    },
    mounted(){
        if(this.$store.state.user.role == 'teacher' || this.$store.state.IntroWatched == true){
            this.showIntro= false;
            this.videoLoading = false;
            this.show = true
        }
        else{
            this.showIntro= true;
            this.videoLoading = true;
            this.show = true
        }
    },
    methods:{
        mute_or_unmute(){
            this.introvideoMute = !this.introvideoMute
        },
        videoPlay(){
            var video = document.getElementById('myVideo');
            if (video.paused == true) {
                video.play();
                video.title = 'Click to pause video';
            }
            else {
                video.pause();
                video.title = 'Click to play video';
            }
        },
        videoEnded(){
            this.showIntro= false;
            this.IntroWatched = true;
        },
        checkLoading(){
            this.videoLoading = false
        },
        skipVideo(){
            this.showIntro= false;
            
            this.IntroWatched = true;
        }
    },
    computed:{
        User(){
            return this.$store.state.user;
        },
        IntroWatched:{
            set(value){
                this.$store.state.IntroWatched = value;
            },  
            get(){
                return this.$store.state.IntroWatched;
            }
        }
    }
};
</script>
<style scoped>
.myVideoWrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
#myVideo{
    cursor: pointer;
    max-width: calc(95vw);
    max-height: calc(95vh);
}
.myVideoWrapper .mute-btn{
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    padding: 15px;
    opacity: 0.5;
}
.myVideoWrapper .mute-btn:hover{
    opacity: 1;
}
.intro-video-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
}
.homePageMenu li a, .homePageMenu li{
    font-family: 'Lomo', sans-serif;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    -webkit-text-stroke: 3px black;
}
li.large-text{
    line-height: 1;
    width: min-content;
    margin: 15px 0px;
    transition: all 0.3s;
}
li.large-text:hover::marker, .homePageMenu li a:hover{
    color: #00B917;
}
@media screen and (max-width: 767px){
    .v-image.v-responsive.theme--light .v-responsive__content{
        display: none;
    }
    #HomePage{
        background-position-x: left;
    }
}
@media screen and (max-width: 1000px) and (min-width: 768px){
    #HomePage{
        background-position-x: left;
    }
}
</style>
