import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import 'material-design-icons-iconfont';
import 'material-design-icons';
import Vue2TouchEvents from 'vue2-touch-events'
import bus from './services/utils/Event.js'
Vue.config.productionTip = false;
Vue.use(Vue2TouchEvents)
Vue.prototype.$bus = bus;

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
