<template>
    <v-container fluid fill-height id="response-mask">
        <v-layout align-center justify-center>
            <v-flex xs10 sm8 md4 class="response-box">
                
                <v-card class="elevation-4 response-wrapper" :class="wrapperClass">
                <v-img
                    v-if="wrapperClass == 'success'"
                    class="success-tick"
                    src='@/assets/img/success-tick.png'
                    max-width="50"
                ></v-img>
                <v-img
                    v-if="wrapperClass == 'error'"
                    class="error-cross"
                    src='@/assets/img/error-cross.png'
                    max-width="50"
                ></v-img>
                <v-img
                    v-if="wrapperClass == 'info' || wrapperClass == 'info hasAction'"
                    class="info-sign"
                    src='@/assets/img/success-tick.png'
                    max-width="50"
                ></v-img>
                <h2 v-if="wrapperClass == 'success'">Successful!</h2>
                <h2 v-if="wrapperClass == 'error'">Error!</h2>
                <h2 v-if="wrapperClass == 'info'|| wrapperClass == 'info hasAction'">Successful!</h2>
                <div class="card-content">
                    {{Msg}}
                </div>
                </v-card>
                <v-btn class="close-btn elevation-1" v-on:click="$emit('close')" v-if="wrapperClass != 'info hasAction'">OK</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: 'ResponseMsg',
    props: ['Msg', 'wrapperClass', 'data'],
    data(){
        return{
        }
    },
    methods:{
    },
    computed: {
    }
}
</script>
<style>
#response-mask{
    position: fixed;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 99999;
}
#response-mask .response-box .response-wrapper{
    padding: 20px 50px;
    text-align: center;
    border-radius: 5px 5px 0 0;
}
#response-mask .card-header p{
    font-size: 23px;
    margin-bottom: 15px;
}
#response-mask .response-box{
    position: relative;
    border-radius: 5px;
}
#response-mask .response-box .success, #response-mask .response-box .error, #response-mask .response-box #response-mask .info{
    background-color: #fff !important;
}
#response-mask .response-box .success h2{
    color: #23B53F;
    margin-bottom: 15px;
}
#response-mask .response-box .error h2{
    color: #FF4141;
}
#response-mask .response-box .info h2{
    color: #23B53F;
}
#response-mask .response-box .close-btn{
    font-weight: 300;
    width: 100%;
    border-radius: 0 0 5px 5px;
    padding-top: 25px;
    padding-bottom: 25px;
}
#response-mask .response-box .action-close-btn, .response-box .action-primary-btn{
    font-weight: 300;
    width: 50%;
    padding-top: 25px;
    padding-bottom: 25px;
}
#response-mask .response-box .action-close-btn{
    border-radius: 0 0 0px 5px;
}
#response-mask .response-box .action-primary-btn{
    border-radius: 0 0 5px 0px;
}
#response-mask .response-box .card-content{
    color: #000000;
}
#response-mask .success-tick, .error-cross, .info-sign{
    margin: 0 auto 15px;
}
</style>