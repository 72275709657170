<template>
    <v-app>
        <v-main transition="fade">
            <router-view></router-view>
            <span class="version">V 0.0.26</span>
            <transition name="fade"><response-msg v-if="!!ResponseMsg" :Msg="ResponseMsg" :wrapperClass="ResponseClass" :data="responseData" @close="ResponseMsgClose"></response-msg></transition>
        </v-main>
    </v-app>
</template>

<script>
import ResponseMsg from '@/components/ResponseMsg.vue';
export default {
    name: 'App',
    components: {
        ResponseMsg,
    },
    methods:{
        ResponseMsgClose(){
            this.$store.state.responseMsg = '';
            this.$store.state.ResponseClass = '';
            this.$store.state.responseData = '';
        },
    },
    computed:{
        ResponseMsg:{
            get(){
                return this.$store.state.responseMsg;
            },
            set(value){
                this.$store.state.responseMsg = value;
            }
        },
        ResponseClass(){
            return this.$store.state.ResponseClass;
        },
        responseData(){
            return this.$store.state.responseData;
        },
    },
    mounted(){
        this.$store.dispatch('initializeDatabase');
    },
    watch: {
    
    }
}
</script>
<style>
@font-face {
  font-family: "Lomo";
  src: local("Lomo"),
   url(./assets/Font.TTF) format("truetype");
}
html{
    scroll-behavior: smooth;
}
a {
    text-decoration: none;
}
header .v-toolbar__content{
    height: auto !important;
    padding: 0;
}
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
.version{
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.2);
    color: #fff;
    z-index: 999;
}
/*.v-application{
    margin-bottom: 50px;
} */
.v-application button{
    transition: 0.3s all;
}
.v-application a{
    transition: 0.3s all;
}
.pageLoader{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,2555,255,0.9);
}
.pageLoader .v-progress-circular{
    height: calc(20vh) !important;
    width: calc(15vw) !important;
    margin: auto;
}
#curriculumPage, #HomePage, .bg-container{
    background-position-x: left;
    background-attachment: fixed;
    padding: 0px;
}
.standard-bg{
    background: url('~@/assets/main-bg.png');
    background-size: 100% 105%;
}
.custom-font{
    font-family: 'Lomo', sans-serif;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    -webkit-text-stroke: 3px black;
}
.full-screen-loader .progressLoader{
    width: calc(20vw) !important;
    height: calc(20vh) !important;
    margin: auto;
}
.full-screen-loader{
    width: 100%;
    height: 100%;
    position: fixed;
    align-self: start;
    background-color: rgba(255,255,255,0.5);
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
}
.moveable-control-box{
    display: none !important;
}
.skip, button.skip.v-btn, button.skip.v-btn.v-btn--disabled.custom-font{
    position: fixed;
    right: 0;
    bottom: 50px;
    background-color: rgba(255,255,255,0.8) !important;
    text-shadow: 0px 0px 0px rgba(0,0,0,0.5);
    z-index: 998;
    padding: 10px 25px !important;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
    
}
button.skip.v-btn, button.skip.v-btn.v-btn--disabled.custom-font{
    color: #fff !important;
    display: inline-block;
    height: auto !important;
}
.skip:hover, button.skip.v-btn:hover, button.skip.v-btn.v-btn--disabled.custom-font:hover{
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    color: #00B917!important;
}
.timer{
    position: fixed;
    right: 0;
    top: 0;
}


@media screen and (max-width: 767px){
    .large-text{
        font-size: 26px;
    }
    .medium-text{
        font-size: 18px;
    }
    #curriculumPage, #HomePage, .bg-container{
        background-size: cover;
    }
}
@media screen and (max-width: 1000px) and (min-width: 768px){
    .large-text{
        font-size: 36px;
    }
    .medium-text{
        font-size: 26px;
    }
    #curriculumPage, #HomePage, .bg-container{
        background-size: cover;
    }
}
@media screen and (min-width: 1001px){
    .large-text{
        font-size: 50px;
    }
    .medium-text{
        font-size: 36px;
    }
    #curriculumPage, #HomePage, .bg-container{
        background-size: 100% 105%;
    }
}



@media screen and (max-width: 600px){

}
@media screen and (max-width: 601px) and (min-width: 767px){
    
}
@media screen and (max-width: 959px) and (min-width: 768px){
    
}
@media screen and (max-width: 1263px) and (min-width: 960px){
    
}
@media screen and (min-width: 1264px){
    
}
</style>
