import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        signinActionInProgress: false,
        user: null,
        responseMsg: '',
        ResponseClass: '',
        responseData: '',
        fetching: false,
        Rooms: null,
        Lessons: null,
        LearningObjectives: null,
        Activities: null,
        Activity: null,
        objectiveSkip: false,
        IntroWatched: false,
        ActivityIntroWatched: false,
        assessmentCoins: 0,
        activityTime: "00:00",
        mute: false,
        profileImage: null,
        nextActivity: null,
        fromLink :null,
        /** variables to keep track of narrations */
        NarrationsStatus: {
          startActivity1ANarration: false,
          clickMeasuringTapeNarration: false,
          openMeasuringTapeNarration: false,
          openAnalyzerNarration: false,
          clickStar2BNarration: false,
          clickStarDrAstroNarration: false,
          clickNebula2BNarration: false,
          clickNebulaDrAstroNarration: false,
          nebulaEndNarration: false,
          clickAnalyzerNarration: false,
          endActivity1ANarration: false,
          /** end of variables off narrations */
        },
        ActivityShuffled: null,
        RoomDB: null
        
    },
    mutations: {
        setEmptyfield(state){
          state.user = null;
          state.Rooms= null;
          state.Lessons= null;
          state.LearningObjectives= null;
          state.Activities= null;
          state.Activity= null;
          state.objectiveSkip= false;
          state.fromLink =null;
          state.RoomDB = null; 
          localStorage.removeItem("user");       
        },
        setUser(state, payload) {
          state.user = payload;
        },
        setSignInActionInProgress(state, payload) {
          state.signinActionInProgress = payload;
        },
        setResponseMessage(state,payload){
          state.responseMsg = payload;
        },
        setResponseClass(state,payload){
          state.ResponseClass = payload;
        },
        setResponseData(state,payload){
          state.responseData = payload;
        },
        setRooms(state, payload){
          state.Rooms = payload
        },
        setLessons(state, payload){
          state.Lessons = payload;
        },
        setActivities(state, payload){
          state.Activities = payload;
        },
        setActivity(state, payload){
          state.Activity = payload;
        },
        setAssessmentCoins(state, payload) {
          state.assessmentCoins = payload;
        },
        setActivityTime(state, payload) {
          state.activityTime = payload;
        },
        setMute(state, payload){
          state.mute = payload;
        },
        setProfileImage(state, payload){
          state.profileImage = payload;
        },
        setLearningObjectives(state, payload){
          state.LearningObjectives = payload;
        },
    },
    actions: {
      initializeDatabase({state}){
        if(localStorage.getItem("RoomDB")){
          state.RoomDB = JSON.parse(localStorage.getItem("RoomDB"));
        }
        else{
            var RoomDB = {
                id: '6a6b5c5d9e9',
                title: 'Earth and Space', 
                color: '#6B2EB9',
                room_name: 'EARTH & SPACE',
                room_color: '#6B2EB9',
                left_room_icon: 'earth-space-icon_1.png',
                Lessons: [
                    {
                        id: '01',
                        name: 'THE MILKY WAY',
                        title: 'LESSON 1: THE MILKY WAY',
                        class: 'active',
                        Activities: [
                            {id: '011', name: 'Key Science Lingo', title: 'Activity 1: Key Science Lingo', class:'active'}, 
                            {id: '012', name: 'Milky Way Model - (Free exploration)', title: 'Activity 1A: Milky Way Model - (Free exploration)', class:'active'}, 
                            {id: '013', name: 'Putting Together the Milky Way', title: 'Activity 2: Putting Together the Milky Way', class:'active'},
                            {id: '014', name: 'Assessment: Star Bubbles', title: 'Activity 3: Assessment: Star Bubbles', class:'active'}
                        ], 
                    },
                    {
                        id: '02',
                        name: 'THE SOLAR SYSTEM', 
                        title: 'LESSON 2: THE SOLAR SYSTEM', 
                        class: 'disabled',
                        Activities: [
                            {id: '015', name: 'Key Science Lingo', title: 'Activity 4: Key Science Lingo', class:'disabled'}, 
                            {id: '016', name: 'Our Solar System - Planet Characteristics (Free Exploration)', title: 'Activity 4A: Our Solar System - Planet Characteristics (Free Exploration)', class:'disabled'}, 
                            {id: '017', name: 'Level 1 - Planets', title: 'Activity 5: Level 1 - Planets', class:'disabled'},
                            {id: '018', name: 'Level 2 - Planets', title: 'Activity 5A: Level 2 - Planets', class:'disabled'},
                            {id: '019', name: 'Assessment', title: 'Activity 6: Assessment', class:'disabled'}
                        ], 
                    },
                    {
                        id: '03', 
                        name: 'EARTH', 
                        title: 'LESSON 3: EARTH', 
                        class: 'disabled',
                        Activities: [
                            {id: '020', name: 'Key Science Lingo', title: 'Activity 7: Key Science Lingo', class:'disabled'}, 
                            {id: '021', name: 'Exploring The Stars', title: 'Activity 8: Exploring The Stars', class:'disabled'}, 
                            {id: '022', name: 'Exploring Different Star Sizes', title: 'Activity 9: Exploring Different Star Sizes', class:'disabled'},
                            {id: '023', name: 'Exploring Distance And Apparent Brightness', title: 'Activity 10: Exploring Distance And Apparent Brightness', class:'disabled'},
                            {id: '024', name: 'Shadows', title: 'Activity 11: Shadows', class:'disabled'}
                        ]
                    }
                ]
            }
            localStorage.setItem("RoomDB", JSON.stringify(RoomDB));
            state.RoomDB = RoomDB;
        }
      },
        getNextActivity({state}, {Activity_id}){
          var Activities = state.Activities;
          var nextItem;
          var index = Activities.findIndex(x => x.id == Activity_id);
          if(index >= 0 && index < Activities.length - 1){
            nextItem = Activities[index + 1];
          }
          state.nextActivity = nextItem;
        },
        userLogin({commit, state, dispatch}, {username,password}){
            var user = null;
            dispatch('initializeDatabase');
            if(username == 'test@123' && password == 'dx456'){
              user = {name: 'Test Account', email: 'test@gmail.com', role: 'student'}
              commit('setUser', user);
              localStorage.setItem("user", JSON.stringify(user));
              
              commit('setSignInActionInProgress', false);
              router.push({ path: '/' }); 
            }
            else if(username == 'teacher@123' && password == 'dx456'){
                user = {name: 'Teacher Account', email: 'teacher@gmail.com', role: 'teacher'}
                commit('setUser', user);
                localStorage.setItem("user", JSON.stringify(user));
                commit('setSignInActionInProgress', false);
                state.IntroWatched = true;
                router.push({ path: '/' });
            }
            else{
                commit('setResponseMessage', 'Incorrect username or password.');
                commit('setResponseClass', 'error');
                commit('setUser', user);
                commit('setSignInActionInProgress', false);
            }
        },
        userLogout({commit}){
          localStorage.clear();
          commit('setEmptyfield');
          router.push({ path: 'sign-in' });
        },
        getRooms({commit, state}){
          var Rooms = [
            {id:'1', title: 'Matter', color: '#F0529D'}, 
            {id:'2', title: 'Energy In Motion', color: '#E76600'}, 
            {id:'3', title: 'Forces', color: '#EE252F'}, 
            {id:'6a6b5c5d9e9', title: 'Earth and Space', color: '#6B2EB9'}, 
            {id:'4', title: 'Weather and Climate', color: '#1D72EF'}, 
            {id:'5', title: 'Diversity of Living Things', color: '#00A47F'}, 
            {id:'6', title: 'Living Organisms', color: '#007540'}, 
            {id:'7', title: 'Weather and Climate', color: '#CC8F00'}
          ]
          commit('setRooms', Rooms);
          state.fetching = false
        },
        getLearningObjective({commit, state}, Lesson_id){
          var objectives = [];
          if(Lesson_id == '01'){
            objectives = {
                id : 'Lesson_objective_1',
                PrimaryMsg: [
                  'Recognize that a galaxy consists of gas, dust, and many stars, including any objects orbiting the stars. Identify our home galaxy as the Milky Way.',

                  'Recognize and explain that authentic scientific investigations frequently do not parallel the steps of "the scientific method."',
                  
                  'Support an argument that differences in the apparent brightness of the sun compared to other stars is due to their relative distances from Earth.'],
                Narration : "",
                background: "purple-galaxy-background.png",
                room_name: 'Earth and Space',
                first_activity_id: 'activity_1',
            };
          }
          else if(Lesson_id == '02'){
            objectives = {
                id : 'Lesson_objective_2',
                PrimaryMsg: [
                  'Recognize the major common characteristics of all planets and compare/contrast the properties of inner and outer planets.',

                  'Distinguish among the following objects of the Solar System -- Sun, planets, moons, asteroids, comets -- and identify Earth’s position in it.',
                  
                  'Explain the difference between an experiment and other types of scientific investigation.',

                  'Identify the different types of scientific investigations. (Model, Simulation, Repeated Systematic Observation, Field Studies, Controlled Experiment).',

                  'Recognize and explain that authentic scientific investigations frequently do not parallel the steps of "the scientific method."',

                  'Represent data in graphical displays to reveal patterns of daily changes in length and direction of shadows, day and night, and the seasonal appearance of some stars in the night sky.'
                ],
                Narration : "Clarification Statement: Examples of patterns could include the position and motion of Earth  with respect to the sun and selected stars that are visible only in particular months.",
                background: "purple-galaxy-background.png",
                room_name: 'Earth and Space',
                first_activity_id: 'activity_2',
            };
          }
          state.RoomDB.Lessons.find(e=>e.id == Lesson_id).LearningObjectivesVisited = 'visited';
          localStorage.setItem("RoomDB", JSON.stringify(state.RoomDB));
          commit('setLearningObjectives', objectives);
          state.fetching = false;
        },
        getLessons({commit, state/*, dispatch */}, Room_id){
          var Lessons = [];
          if(state.RoomDB){
            Lessons = state.RoomDB;
            commit('setLessons', Lessons);
            state.fetching = false
          }
          else{
            if(Room_id == '6a6b5c5d9e9'){
              Lessons = {
                Lessons:  [
                  {id: '01', name: 'THE MILKY WAY', title: 'LESSON 1: THE MILKY WAY', class: 'active'},
                  {id: '02', name: 'THE SOLAR SYSTEM', title: 'LESSON 2: THE SOLAR SYSTEM', class: 'disabled'},
                  {id: '03', name: 'EARTH', title: 'LESSON 3: EARTH', class: 'disabled'}
                ],
                room_name: 'EARTH & SPACE',
                room_color: '#6B2EB9',
                left_room_icon: 'earth-space-icon_1.png'
              };
              commit('setLessons', Lessons);
              state.fetching = false;
            }
            else{
              Lessons = [];
              commit('setLessons', Lessons);
              state.fetching = false
            }
          }
        },
        getActivities({commit, state/*, dispatch */}, Lesson_id){
          var Activities;
          if(state.RoomDB){
            Activities = state.RoomDB.Lessons.find(e=> e.id == Lesson_id).Activities;
          }
          else{
            if(Lesson_id == '01'){
              Activities = [
                {id: '011', name: 'Key Science Lingo', title: 'Activity 1: Key Science Lingo', class:'active'}, 
                {id: '012', name: 'Milky Way Model - (Free exploration)', title: 'Activity 1A: Milky Way Model - (Free exploration)', class:'active'}, 
                {id: '013', name: 'Putting Together the Milky Way', title: 'Activity 2: Putting Together the Milky Way', class:'active'},
                {id: '014', name: 'Assessment: Star Bubbles', title: 'Activity 3: Assessment: Star Bubbles', class:'active'}
              ];
            }
            else if(Lesson_id == '02'){
              Activities = [
                {id: '015', name: 'Key Science Lingo', title: 'Activity 4: Key Science Lingo', class:'disabled'}, 
                {id: '016', name: 'Our Solar System - Planet Characteristics (Free Exploration)', title: 'Activity 4A: Our Solar System - Planet Characteristics (Free Exploration)', class:'disabled'}, 
                {id: '017', name: 'Level 1 - Planets', title: 'Activity 5: Level 1 - Planets', class:'disabled'},
                {id: '018', name: 'Level 2 - Planets', title: 'Activity 5A: Level 2 - Planets', class:'disabled'},
                {id: '019', name: 'Assessment', title: 'Activity 6: Assessment', class:'disabled'}
              ];
            }
            else if(Lesson_id == '03'){
              Activities = [
                {id: '020', name: 'Key Science Lingo', title: 'Activity 7: Key Science Lingo', class:'disabled'}, 
                {id: '021', name: 'Exploring The Stars', title: 'Activity 8: Exploring The Stars', class:'disabled'}, 
                {id: '022', name: 'Exploring Different Star Sizes', title: 'Activity 9: Exploring Different Star Sizes', class:'disabled'},
                {id: '023', name: 'Exploring Distance And Apparent Brightness', title: 'Activity 10: Exploring Distance And Apparent Brightness', class:'disabled'},
                {id: '024', name: 'Shadows', title: 'Activity 11: Shadows', class:'disabled'}
              ];
            }
          }
          
          commit('setActivities', Activities);
          state.fetching = false;
          //dispatch('getActivity', Activities[0].id);
        },
        getActivity({state, commit, dispatch}, activity_id){
          var Activity;
          if(activity_id == '011'){
            Activity = {
              id: '011',
              type: 'key-science-lingo-right',
              class: 'key-science-lingo-1',
              starting_animation: 'Jump-out.mp4',
              ending_animation: 'jumps-in.mp4',
              starting_background: 'Key-Lingo-Background-wreaked.jpg',
              ending_background: 'Key-Lingo-Background-clean.jpg',
              narration_2b: 'Dr. Astro! Everything is destroyed! Kids can you help me restore this?',
              hint: 'Match the word to its correct definition.',
              assets: [
                {question:'Planet', answer: 'A large body in space that orbits a star and does not produce light of its own'}, 
                {question:'Galaxy', answer: 'A system of millions or billions of stars, together with gas and dust, held together by gravitational attraction.'}, 
                {question:'Milky Way Galaxy', answer: 'A large spiral system consisting of several hundred billion stars, one of which is the Sun.'}, 
                {question:'Nebula', answer: 'A giant cloud of gas and dust in outer space, regions where new stars are beginning to form'}, 
                {question:'Solar System', answer: 'A system of planets and other bodies that orbits a star.'},
                {question:'Star', answer: 'A large object in space that is made of gas and produces its own light'},
                {question:'Light Year', answer: 'The distance that light travels through empty space in one year.'}
              ],
            }
          }
          else if(activity_id == '012'){
            Activity = {
              id: '012',
              type: 'examiner'
            }
          }
          else if(activity_id == '013'){
            Activity = {
              id: '013',
              type: 'puzzle'
            }
          }
          else if(activity_id == '014'){
            Activity = {
              id: '014',
              type: 'assesment',
            }
          }
          else if(activity_id == '015'){
            Activity = {
              id: '015',
              type: 'key-science-lingo-left',
              class: 'key-science-lingo-2',
              starting_animation: '2b-jet-pack-jump-out.mp4',
              ending_animation: '2b-jetpack -jump-in.mp4',
              starting_background: 'purple-galaxy-background.png',
              ending_background: 'purple-galaxy-background.png',
              narration_2b: 'We are now in the solar system, can you help me put together these definitions with their corresponding words?',
              hint: 'Match the word to its correct definition.',
              assets: [
                {question:'Rotation', answer: 'Is the movement of an object in a circular motion. A two-dimensional object rotates around a center (or point) of rotation. A three-dimensional object rotates around a line called an axis.'}, 
                {question:'Asteroid', answer: 'A rocky or metallic object that orbits the Sun and is much smaller than a planet.'}, 
                {question:'Meteor', answer: 'A small body of matter that enters Earth’s atmosphere due to gravity, appearing as a streak of light as a result of being heated.'}, 
                {question:'Moon', answer: 'A natural satellite that revolves around a planet.'}, 
                {question:'Comet', answer: 'An object made of rock, ice, dust, and gas that revolves around the Sun.'},
                {question:'Mass', answer: 'The amount of matter an object has.'},
                {question:'State of Matter', answer: 'One of the three main types of matter (solid, liquid, gas)'},
                {question:'Orbit', answer: 'An orbit is a repeating path that one object in space takes around another.'}
              ],
            }
          }
          else if(activity_id == '017'){
            Activity = {
              id: '017',
              type: 'solarSystemActivity5',
            }
          }
          else if(activity_id == '020'){
            Activity = {
              id: '020',
              type: 'key-science-lingo-left',
              class: 'key-science-lingo-2',
              starting_animation: '2b-jet-pack-jump-out.mp4',
              ending_animation: '2b-jetpack -jump-in.mp4',
              starting_background: 'purple-galaxy-background.png',
              ending_background: 'purple-galaxy-background.png',
              narration_2b: 'We are now in the solar system, can you help me put together these definitions with their corresponding words?',
              hint: 'Drag and drop the word to its corresponding definition',
              assets: [
                {question:'Apparent Brightness: ', answer: 'A measure of the amount of light received by Earth from a star or other object—that is, how bright an object appears in the sky, as contrasted with its luminosity'}, 
                {question:'Line Graph', answer: 'Is a type of chart used to show information that changes over time. We plot line graphs using several points connected by straight lines.'}, 
                {question:'Luminosity', answer: 'The rate at which a star or other object emits electromagnetic energy into space; the total power output of an object.'}, 
                {question:'Shadow', answer: ' An object from which no light can pass through is called an opaque object.'}, 
                {question:'Sunrise', answer: "Is the daily disappearance of the Sun below the horizon due to Earth's rotation."},
                {question:'Sunset', answer: "is the daily disappearance of the Sun below the horizon due to Earth's rotation."},
              ],
            }
          }else if(activity_id == '021'){
            Activity = {
                id: '021',
                type: 'exploring-stars'
            }
          }else if(activity_id == '023'){
            Activity = {
                id: '023',
                type: 'exploring'
            }
          }
          else{
            Activity = {
              id: '01',
              type: 'no-activity'
            }
          }
          commit('setActivity', Activity);
          //var currentActivity = state.Activities.find(e => e.id == Activity.id);
          dispatch('getNextActivity', {Activity_id: activity_id})
          state.fetching = false;
        }
    },
    modules: {},
});
